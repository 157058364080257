import { DateUtils } from 'react-frontend-utils'
import store from 'store'


/**
 * Holds the global variables for the frontend
 */
export const Global = {
    
    user: null,               //object of type User, the currently logged in user or null if none
   
    
    getDateFormat: () => {
        const fmt = store.get("dateFormat");
        if (!fmt)
            return DateUtils.DateFormatType.ISO8601;
        else
            return fmt;    
    },
    
    setDateFormat: (format) => {
        if (!DateUtils.DateFormatType.isOneOf(format)) {
            console.log("Invalid DateFormatType: " + format);
            return;
        }
        store.set("dateFormat", format);    
    },
    
    
    
    dateFormat: (date) => {
        return DateUtils.dateFormat(date, Global.getDateFormat());
    },
    
    checkInTimeFormat: (date) => {
        return DateUtils.timeFormat(date, Global.getDateFormat());
    }
    
   
    
};
