import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { IconButton, Tooltip, Grid, Typography, Paper, TextField, Button, Collapse } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import EmailIcon from '@material-ui/icons/Email';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PrintIcon from '@material-ui/icons/Print';

import { ThemeColors } from '../Theme'
import { Job } from '../models/Job'
import { PagedJobsList } from '../models/PagedJobsList'
import { Global } from '../models/Global'
import { Community} from '../models/Community'

import { RestComponent, Permissions } from 'react-frontend-utils' 
import { multilineJSX, StyledTooltip, TextEntryPopover } from 'react-frontend-utils'

import { DateUtils, SummaryWidget, ManageTextField, ManageBitwiseCheckboxes, PopupMenu, DateRangeSelector } from 'react-frontend-utils'

import epassIcon from '../assets/epassIcon.jpg';
import passIcon from '../assets/pvcpassIcon.jpg';
import bulkMailIcon from '../assets/bulkMailIcon.png'
import { CommunitySelectorPopup } from '../components/CommunitySelectorPopup';

/**
 * Page Displays a List of all Jobs
 * 
 * On load, the initial Page of Jobs are fetched from the backend.  A filtered list can be queried later on the page. 
 * 
 */


const archivedStateBit = (1 << Job.State.ARCHIVED.bit);

const MIN_TIME_BETWEEN_BULK_MAILING = 12 * 3600 * 1000;  // 12 hours

export class JobsListPage extends RestComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            color: 'gray',
            fontSize: '9pt',
            flexGrow: 1
        },
        paper: {
            padding: 0,
            marginBottom: 20
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            marginBottom: 10,
            marginTop: 10
        },
        tableHeader: {
            borderBottom: '2px solid ' + ThemeColors.appBarBackground,
            textAlign: 'left',
            paddingBottom: 5,
            paddingRight: 10,
            marginBottom: 8,
            fontSize: 13,
            color: ThemeColors.darkGray,
            fontWeight: 'normal',
            textTransform: 'uppercase'
        },
        tableDataWithBorder: {
            textAlign: 'left',
            fontSize: 13,
            paddingRight: 10,
            borderBottom: '1px solid lightGray'
        },
        tableData: {
            textAlign: 'left',
            fontSize: 14,
            paddingRight: 10,
            verticalAlign: 'center'
        },
        status: {
            margin: 'auto',
            alignContent: 'center',
            width: 90,
            textTransform: 'uppercase',
            padding: 2, 
            borderRadius: 2, 
            color: 'white', 
            textAlign: 'center'
        },
    };
    
    
    static currentYear = (new Date()).getFullYear();

    
     //options for the search time range pulldown
    _searchTimeRangeOptions = [
        {label: "Today", startTime: DateUtils.startOfToday().valueOf()},
        {label: "Last 2 days", startTime: DateUtils.startOfToday().valueOf() - (2 * DateUtils.MS_PER_DAY)},
        {label: "Last 7 days", startTime: DateUtils.startOfToday().valueOf() - (7 * DateUtils.MS_PER_DAY)},
        {label: "Last 30 days", startTime: DateUtils.startOfToday().valueOf() - (30 * DateUtils.MS_PER_DAY)},
        {label: "Last 60 days", startTime: DateUtils.startOfToday().valueOf() - (60 * DateUtils.MS_PER_DAY)}, 
        {label: "Last 90 days", startTime: DateUtils.startOfToday().valueOf() - (90 * DateUtils.MS_PER_DAY)},
        {label: "Year to Date", startTime: DateUtils.startOfYear().valueOf()},
        {label: "Previous Year", startTime: DateUtils.startOfYear(JobsListPage.currentYear-1).valueOf(), endTime: DateUtils.lastDayOfYear(JobsListPage.currentYear-1).valueOf()},
        {label: "Any", startTime: null}
    ];
    

    _searchString = "";
    _simpleStatesToSearch = null;  //initially all states
    _claimedByMe = false;          //if true, add claimed by me to the search params
    _jobSort = "NEWEST_FIRST";

    _doAdvancedSearch = false;
    _availableGroups;

    constructor(props) {
        super(props);
                    
        this.state.jobsPage = null;         //last fetched page - a PagedJobsList
               
        this.state.searchFromDate = DateUtils.jsonDateString(DateUtils.startOfYear(), false);           //JsonDateString or null
        this.state.searchToDate = null;                                                                 //JsonDateString or null
        
        this.state.groupToSearch = null;            //Specific group to search for
        this.state.groupToSearchValue = null;
        
        this.state.stateCheckboxes = 0;             //Bitwise selected states to search for, from checkboxes only!
        this.state.patronDataToSearch = "";         //Data in an Job field to search (address, Membership ID, etc)
        this.state.journalDataToSearch = "";            //Data in a journal field
        
        this.state.showAdvancedSearch = false;
        this.state.commentPopupOpen = false;
        this.state.commentJob = null;
        
        this.state.revealSet = new Set();         //Set of jobs to reveal history
        
        this._availableGroups = Global.user.databases;

        this.state.bulkCommunities = [];
    }
    
    
    
    /**
     * When the page loads, immediately fetch the first page of jobs
     */
    componentDidMount() {
        super.componentDidMount();
        this._updateSize();
        window.addEventListener("resize", this._updateSize);
        
        //fetch jobs, first page
        this._fetchJobs(); 
    }
    
  
    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("resize", this._updateSize);
    }

    //callback when window changes size
    _updateSize = () => {
        this.setState({ isSmall: window.innerWidth < 1300, isLarge: window.innerWidth > 1500 });  
    }
    
    
    //Fetch a specific page, using search fields if set. If the _doAdvancedSearch variable is true, do an advanced search, otherwise simple
    _fetchJobs = (page = 0, forDownload = false) => {
        
        let queryString = "?";
        
        if (this._doAdvancedSearch) {
            if (this.state.groupToSearch)
                queryString += "group=" + this.state.groupToSearch + "&";                
            if (this.state.stateCheckboxes)  //state checkboxes are set
                queryString += "states=" + this.state.stateCheckboxes + "&";
            if (this.state.searchFromDate)
                queryString += "fromDate=" + this.state.searchFromDate + "&";
            if (this.state.searchToDate) {
                
                const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
                queryString += "toDate=" + endOfDayDate + "&";
                
            }
            if (this.state.patronDataToSearch)
                queryString += "patronData=" + encodeURIComponent(this.state.patronDataToSearch) + "&";
            
            if (this.state.journalDataToSearch)
                queryString += "journalData=" + encodeURIComponent(this.state.journalDataToSearch) + "&";
                
        }
        else {  //simple
            if (this._simpleStatesToSearch) //has a numeric value, search for specific states
                queryString += "states=" + this._simpleStatesToSearch + "&";        

            if (this._claimedByMe)
                queryString += "claimedByMe=true&";
        }
        
        queryString += "jobSort=" + this._jobSort + "&page=" + page;
        
        this.incrementBusy();

        if (forDownload) {
            const filename = "Job Export on " + DateUtils.downloadTimeString() + ".csv";
            this.secureFileDownload("/pr/jobs/export", filename, this._downloadResponse, this._fetchErrorCallback, queryString); 
        }
        else {
            this.secureJSONFetch("/pr/jobs", {},
                                this._fetchJobsCallback, this._fetchErrorCallback, queryString); 
        }
        
    }

    _downloadResponse = () => {
        this.decrementBusy();
        console.log("Download complete");
    }
   
    
    //Callback for fetching JObs - response is json of PagedJobsList
    _fetchJobsCallback = (response) => {
        if (response) {            
            const ap = new PagedJobsList(response);
            this.setState({jobsPage: ap});
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
 
    //If the Job is in the reveal list, remove it, if it is not, add it (toggle the Job)
    _revealJobJournal = (job) => {
        const toReveal = this.state.revealSet;
        
        if (toReveal.has(job))
            toReveal.delete(job);
        else
            toReveal.add(job);
        
        this.setState({revealSet: toReveal});
    }
    

    //Attempt to claim the Job, if successful, select it
    _claim = (job) => {
        
        this.incrementBusy();
        if (job.claimedByUser()) {  //if we've already claimed it, release it instead
            this.secureJSONFetch("/pr/jobs/" + job.id + "/release", {method: "POST"},
                                this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        }
        else {  //claim
             this.secureJSONFetch("/pr/jobs/" + job.id + "/claim", {method: "POST"},
                                this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        }
        
    }

 
 
    //fetch the previous page
    _prevPage = () => {  
        
        if (!this.state.jobsPage || this.state.jobsPage.isFirst())
            return;
        
        this._fetchJobs(this.state.jobsPage.pageNo-1); 
        window.scrollTo(0, 0);  //jump to the top

    }
    
    //fetch the next page
    _nextPage = () => {  
        
        if (!this.state.jobsPage || this.state.jobsPage.isLast())
            return;
        
        this._fetchJobs(this.state.jobsPage.pageNo+1); 
        window.scrollTo(0, 0);  //jump to the top

    }
    
    
    _forceRelease = (job) => {
        if (!Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES))
            return;
        
        this.incrementBusy();
        this.secureJSONFetch("/pr/jobs/" + job.id + "/forceRelease", {method: "POST"},
                             this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        
    }


    _requeue = (job) => {
        if (!Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES))
            return;
        
        this.incrementBusy();
        this.secureJSONFetch("/pr/jobs/" + job.id + "/requeue", {method: "POST"},
                             this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        
    }

    _deliveryClaim = (job) => {
 
        this.incrementBusy();
        this.secureJSONFetch("/pr/jobs/" + job.id + "/requeue?deliveryClaim=true", {method: "POST"},
                             this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        
    }

    _commentOkCallback = (text) => {
        if (Global.user.hasPermissionTo(Permissions.PRINT)) {
            this.incrementBusy();
            this.secureJSONFetch("/pr/jobs/" + this.state.commentJob.id + "/comment", {method: "POST", body: text},
                                this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        }
        this.setState({commentPopupOpen: false, commentJob: null});
    }
    
    _download = (job, temporary) => {
        
        this.incrementBusy();

        const query = temporary ? "?temporary=true" : "";

        this.secureJSONFetch("/pr/jobs/" + job.id + "/downloadPhysicalPass" + query, {},
                             (response) => this._downloaded(response, job, temporary), this._fetchErrorCallback); 

    }

    _downloaded = (response, job, temporary) => {
        this.decrementBusy();
        if (response) {  //show pass html document in new window
            
            const windowRight = window.screenLeft + window.outerWidth;   
            const windowWidth = temporary ? 1200 : 600;
            const windowHeight = temporary ? 900 : 800; 
            
            const newWindow = window.open("", "Pass Preview for " + job.id, 'location=off,toolbar=no,scrollbars=yes,resizable=yes,' +
                                      'top=' + window.screenTop + ',left=' + windowRight +',width=' + windowWidth + ",height=" + windowHeight);
        
            newWindow.document.open();  //clear
            newWindow.document.write(response.html);
            newWindow.document.close();

            if (temporary)
                setTimeout(() => newWindow.print(), 500);
    
        }
    }

    _batchPrint = () => {
 
        const jobs = [];
        for (const job of this.state.jobsPage.jobs) {
            if (job.claimedByUser())
                jobs.push(job.id);
        }
        if (jobs.length === 0)
            return;

        let queryString = "?jobIds=";
        for (const job of jobs)
            queryString += job + ",";

        queryString = queryString.slice(0,-1);  //remove last comma

        this.incrementBusy();
        this.secureJSONFetch("/pr/jobs/batchPrint", {},
                              (response) => this._batchResponse(response, jobs), this._fetchErrorCallback, queryString); 
        
    }

    _batchResponse = (response, jobs) => {
        this.decrementBusy();
        if (response) {  //response is "BatchPrint" object from backend, show passes and cover letters in new windows
            
            const isBulkMail = response.isBulkMail;
            
            let topOffset = window.screenTop;
            let leftOffset = window.screenLeft;

            let portraitPassesWindow = null;
            let landscapePassesWindow = null;
            let portraitCoverLettersWindow = null;
            let landscapeCoverLettersWindow = null;
            
            // Place this window at the top left of the screen
            if (response.portraitPasses.cardCount > 0) {
                portraitPassesWindow = window.open("", "Portrait Passes " + Date.now(), 'location=off,toolbar=no,scrollbars=yes,resizable=yes,' +
                                                        'top=' + topOffset + ',left=' + leftOffset +',width=600,height=400');
       
                portraitCoverLettersWindow = window.open("", "Portrait Cover Letters " + Date.now(), 'location=off,toolbar=no,scrollbars=yes,resizable=yes,' +
                                                         'top=' + window.screenTop + ',left=' + window.screenLeft + window.outerWidth +',width=1000,height=400');

                // Place the next landscape windows slightly below and to the right of the portrait window (if there are portrait passes)
                topOffset += 50;
                leftOffset += 50;

            }
            if (response.landscapePasses.cardCount > 0) {
                landscapePassesWindow = window.open("", "Landscape Passes " + Date.now(), 'location=off,toolbar=no,scrollbars=yes,resizable=yes,' +
                                                    'top=' + topOffset + ',left=' + leftOffset +',width=600,height=400');

                landscapeCoverLettersWindow = window.open("", "Landscape Cover Letters " + Date.now(), 'location=off,toolbar=no,scrollbars=yes,resizable=yes,' +
                                                    'top=' + topOffset + ',left=' + window.screenLeft + window.outerWidth +',width=1000,height=400');
                        
            }

                    
            setTimeout(() => {  //wait for popups to appears
                if (response.portraitPasses.cardCount > 0) {
                    if (portraitPassesWindow) {
                        portraitPassesWindow.document.open();  //clear
                        portraitPassesWindow.document.write(response.portraitPasses.html);
                        portraitPassesWindow.document.close();
                    }
                    else
                        console.error("Portrait Passes window did not open");
                    if (portraitCoverLettersWindow) {
                        portraitCoverLettersWindow.document.open();  //clear
                        portraitCoverLettersWindow.document.write(response.portraitCoverLetters.html);
                        portraitCoverLettersWindow.document.close();
                    }
                    else
                        console.error("Portrait Cover Letters window did not open");
                }
                if (response.landscapePasses.cardCount > 0) {
                    if (landscapePassesWindow) {
                        landscapePassesWindow.document.open();  //clear
                        landscapePassesWindow.document.write(response.landscapePasses.html);
                        landscapePassesWindow.document.close();
                    }
                    else
                        console.error("Landscape Passes window did not open");
                    if (landscapeCoverLettersWindow) {
                        landscapeCoverLettersWindow.document.open();  //clear
                        landscapeCoverLettersWindow.document.write(response.landscapeCoverLetters.html);
                        landscapeCoverLettersWindow.document.close();
                    }
                    else
                        console.error("Landscape Cover Letters window did not open");
                }

            }, 500);

            this.showConfirmAlert("Complete Print for " + jobs.length + (isBulkMail ? " Bulk " : " ") + "Print Job" + (jobs.length > 1 ? "s" : ""), 'Complete printing all passes and cover letters. When all jobs have been printed, press COMPLETE to mark jobs as printed. If there was a printing problem, press CANCEL to continue working.',
                                'black', "Cancel", () => this._finishBatch(jobs, isBulkMail), "Complete", 'green');  
            

        }
    }

    _finishBatch = (jobs, isBulkMail) => {

        let queryString = "?jobIds=";
        for (const job of jobs)
            queryString += job + ",";

        queryString = queryString.slice(0,-1);  //remove last comma

        this.incrementBusy();
        this.secureJSONFetch("/pr/jobs/releaseBatchPrint", {method: "POST"},
                              (response) => this._batchDone(response, isBulkMail), this._batchError, queryString); 
    }

    _batchDone = (response, isBulkMail) => {
        this.decrementBusy();
        this._refresh();

        if (isBulkMail)
            this.showConfirmAlert("Mail to Community", "Jobs have been marked Complete. All jobs should be bulk mailed to the community address.");
        else
            this.showConfirmAlert("Mail to Patrons", "Jobs have been marked Complete. Each job should be individually mailed to the address on the cover letter.");
     
    }

    _batchError = (error) => {
        this._fetchErrorCallback(error);
        this._refresh();
    }


    _bulkMail = () => {

        this.incrementBusy();
        this.secureJSONFetch("/pr/groups?bulkonly=true", {},
                            this._fetchBulkCommunitiesCallback, this._fetchErrorCallback); 
    }
        
    //Callback for fetching Communities - response is a list of Community objects that are bulk mail enabled, that are in the users's groups
    _fetchBulkCommunitiesCallback = (response) => {
        if (response) {            
            const communities = response.map(community => new Community(community));
            this.setState({bulkCommunities: communities, communitySelectorPopoverOpen: true});
        }
        this.decrementBusy();
    }

    _selectBulkMailCommunity = (community) => {
        this.setState({communitySelectorPopoverOpen: false});

        const lastMail = community.getLastBulkMailDate();
        if (lastMail && !Global.user.isSuperAdmin()) {
            const now = (new Date()).getTime();
            if (now - lastMail.getTime() < MIN_TIME_BETWEEN_BULK_MAILING) {
                this.showConfirmAlert("Bulk Mail Too Recent", "A bulk mailing for this community was just completed.", 'red');
                return;
            }
        }

        const messageComponent =
            <div>
                <Typography variant="body2">Confirm Request of Bulk Mailing to:</Typography>  

                <div style={{fontSize: 24, margin: 30}}>
                    {multilineJSX(community.bulkMailAddress)}
                </div>

                <Typography variant="body2">Press "Confirm Mailing" to request a bulk mail and bill the community for this job.</Typography>  
            </div>;

        this.showConfirmAlert("Bulk Mail Request", null, 'green', "Cancel", () => this._doBulkMail(community), "Confirm Mailing", 'green', messageComponent);
    }

    _doBulkMail = (community) => {
        this.incrementBusy();
        this.secureJSONFetch("/pr/groups/" + community.name + "/bulkMail", {method: "POST"},
                             this._bulkMailDone, this._fetchErrorCallback); 
    
    }

    _bulkMailDone = (response) => {
        this.decrementBusy();
        this.showConfirmAlert("Success", "Bulk mail request submitted", 'green');
        this._refresh();  //refresh the page  
    }

    
    _delete = (job) => {
        this.showConfirmAlert("Confirm", 'Really Delete Job "' + job.id + '"? Job will be permanently deleted and this operation cannot be undone.',
                                  'black', "Cancel", () => this._finishDelete(job), "Delete", 'red');      
       
    }
    
    _finishDelete = (job) => {
        if (!Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES))
            return;
        
        this.incrementBusy();
        this.secureJSONFetch("/pr/jobs/" + job.id, {method: "DELETE"},
                             this._successIgnoreResponseCallback, this._fetchErrorCallback); 
        
    }
    
    _openInPoolPass = (job) => {
        const url = "https://portal.pool-pass.com/membership?database=" + job.group + "&id=" + job.membershipID;
            
        const windowRight = window.screenLeft + window.outerWidth;    
                
        window.open(url, '_blank', 'toolbar=yes, scrollbars=yes, resizable=yes, ' +
                    'top=' + window.screenTop + ',left=' + windowRight);

    }
    
  
    _completeBulkJob = (job) => {
        this.incrementBusy();
        this.secureJSONFetch("/pr/jobs/" + job.id + "/complete", {method: "POST"},
                            this._successIgnoreResponseCallback, this._fetchErrorCallback); 
    }

    _successIgnoreResponseCallback = (response) => {
        this._refresh();  //refresh the page  
        this.decrementBusy();
    }
      
    //Action items are items for the right popup menu
    _getActionItems = (job) => {
        
        const actionItems = [];

        if (job.stateEnum === Job.State.CLAIMED && Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES)) {
            actionItems.push({label: "Force Release", selectCallback: () => {this._forceRelease(job);}, icon: null});
        }
       
        if (job.requeueable() && Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES))
            actionItems.push({label: "Re-queue", selectCallback: () => {this._requeue(job);}, icon: null});

        if (job.canTriggerDeliveryClaim())
            actionItems.push({label: "Trigger Delivery Claim", selectCallback: () => {this._deliveryClaim(job);}, icon: null});
  
        if (job.downloadable()) {
            actionItems.push({label: "Preview Passes", selectCallback: () => {this._download(job, false);}, icon: null});
            actionItems.push({label: "Print Temporary Passes", selectCallback: () => {this._download(job, true);}, icon: null});
        }

        if (job.kind === "BULK_REQUEST" && job.stateEnum === Job.State.CLAIMED && Global.user.hasPermissionTo(Permissions.PRINT)) {
            actionItems.push({label: "Complete Bulk Job", selectCallback: () => {this._completeBulkJob(job);}, icon: null});
        }
        
        if (job.membershipID)
            actionItems.push({label: "Open in PoolPass", selectCallback: () => {this._openInPoolPass(job);}, icon: null});

        if (Global.user.hasPermissionTo(Permissions.PRINT))
            actionItems.push({label: "Add Comment", selectCallback: () => {this.setState({commentPopupOpen: true, commentJob: job})}, icon: null});

        if (Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES))
            actionItems.push({label: "Delete", selectCallback: () => {this._delete(job);}, icon: null});
        
        return actionItems;
    }
    
    
    _dateChangeCallback = (start, end) => {
        this.setState({searchFromDate: start, searchToDate: end});
    }
    
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
    
    
    _checkboxStateChanged = (json, newValue) => {
        this.setState({stateCheckboxes: newValue});
    }
    
    _fetchAll = () => {
        this._simpleStatesToSearch = null;  //fetch all states
        this._claimedByMe = false;
        this._jobSort = "NEWEST_FIRST";
        this._doAdvancedSearch = false;  //switch to simple search
        this._fetchJobs();  
    }
    
    _fetchQueued = () => {
        
        this._simpleStatesToSearch = 1 << Job.State.QUEUED.bit;
        this._claimedByMe = false;
        this._jobSort = "OLDEST_FIRST";
        this._doAdvancedSearch = false;  //switch to simple search
        this._fetchJobs();
    }

    _fetchQueuedByCommunities = () => {
        
        this._simpleStatesToSearch = 1 << Job.State.QUEUED.bit;
        this._claimedByMe = false;
        this._jobSort = "BY_COMMUNITY";
        this._doAdvancedSearch = false;  //switch to simple search
        this._fetchJobs();
    }
    
    _fetchErrored = () => {
        let states = 1 << Job.State.ERRORED.bit;  //set the on hold bit
        
        this._simpleStatesToSearch = states;
        this._claimedByMe = false;
        this._jobSort = "NEWEST_FIRST";
        this._doAdvancedSearch = false;  //switch to simple search
        this._fetchJobs();
    }

    _fetchClaimedByMe = () => {
        this._simpleStatesToSearch = null;
        this._claimedByMe = true;
        this._jobSort = "OLDEST_FIRST";
        this._doAdvancedSearch = false;  //switch to simple search
        this._fetchJobs();
    }
    
    _advancedSearch = () => {
        this._doAdvancedSearch = true;            //switch to advanced search (simpleStates and claimedByMe ignored)
        this._jobSort = "NEWEST_FIRST";
        this._fetchJobs();        
    }
    
    _export = () => {
        this._doAdvancedSearch = true;            //switch to advanced search
        this._fetchNewestFirst = true;
        this._fetchJobs(0, true);  //fetch for download        
    }
    
    _refresh = () => {
        this._fetchJobs(this.state.jobsPage ? this.state.jobsPage.pageNo : 0);   //just fetch the same page again
    }

    _bulkDeleteJobs = () => {
        this.showConfirmAlert("Confirm", "Delete all Archived Jobs meeting the search parameters? Jobs will be permanently deleted. Recommend downloading these jobs for your records first.",
                                  'black', "Cancel", this._bulkDeleteJobsComplete, "Delete", 'red');      
       
    }

    _bulkDeleteJobsComplete = () => {
        
        let queryString = "?";

        if (this.state.groupToSearch)
            queryString += "group=" + this.state.groupToSearch + "&";  

        queryString += "states=" + archivedStateBit + "&";      //only deleting archived jobs

        if (this.state.searchFromDate)
            queryString += "fromDate=" + this.state.searchFromDate + "&";
        if (this.state.searchToDate) {
            const endOfDayDate = this.state.searchToDate + "T23:59:59";     //search goes through the end of the day
            queryString += "toDate=" + endOfDayDate + "&";
            
        }
        if (this.state.patronDataToSearch)
            queryString += "patronData=" + encodeURIComponent(this.state.patronDataToSearch) + "&";
            
        if (this.state.journalDataToSearch)
            queryString += "journalData=" + encodeURIComponent(this.state.journalDataToSearch) + "&";
                
        this.incrementBusy();

        this.secureJSONFetch("/pr/jobs", {method: "DELETE"},
                                this._successIgnoreResponseCallback, this._fetchErrorCallback, queryString); 
    
    }
    
    
    //Render a row of the table with the specified Job. In the Job is null, render the header row
    _renderRow = (job, key) => {
        
        //Render the header
        if (!job) {
            return (
                <tr key={key} style={this.styles.tableStyle}>
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                    <th style={{...this.styles.tableHeader, width: 180}}>Submit Date</th>
                    <th style={{...this.styles.tableHeader, width: 180, textAlign: 'center'}}>Status</th>
                    <th style={{...this.styles.tableHeader, width: 60, textAlign: 'center'}}>Kind</th>
                    <th style={{...this.styles.tableHeader, width: 40, textAlign: 'center'}}>Count</th>
                    <th style={this.styles.tableHeader}/>
                    <th style={{...this.styles.tableHeader, width: 180}}>Special Handling</th>
                    {this.state.isSmall ? null :
                        <Fragment>
                            <th style={this.styles.tableHeader}>Claimed By</th>
                            <th style={this.styles.tableHeader}>Source</th>
                        </Fragment>
                    }
                    <th style={this.styles.tableHeader}>Deliver To</th>                    
                    <th style={this.styles.tableHeader}>Community</th>
                    {this.state.isSmall ? null :
                        <th style={this.styles.tableHeader}>Membership ID</th>
                    }
                    <th style={{...this.styles.tableHeader, paddingRight: 0, width: '30px'}}/>
                </tr>
            );
        }
      
      
        const submitDateAndTime = DateUtils.timeFormat(job.submitDate);

        let imageFor;
        let imageToolTip;
        switch (job.kind) {
            case "EPASS":
                imageFor = <img alt="ePass" src={epassIcon} height="40" style={{margin: 'auto'}}/>;
                imageToolTip = "EPass Job: " + job.id;
                break;
            case "CARD":
                imageFor = <img alt="pvcPass" src={passIcon} width="40" style={{margin: 'auto'}}/>;
                imageToolTip = "Card Job: " + job.id;
                break;
            case "BULK_REQUEST":
                imageFor = <img alt="bulkRequest" src={bulkMailIcon} width="40" style={{margin: 'auto'}}/>;
                imageToolTip = "Bulk Request Job: " + job.id;
                break;
            default:
                imageToolTip = job.id;
                imageFor = <div>{job.kind}</div>;
        }
        const kindImage = <StyledTooltip title={imageToolTip}>
                                <div style={{display: 'flex', justifyContent: 'center', width: 60}}>{imageFor}</div>
                          </StyledTooltip>;

        let maxChars = this.state.isLarge ? 40 : (this.state.isSmall ? 15 : 30);

        let source = job.source;
        if (source.length > maxChars+10) {
            source = (<StyledTooltip title={multilineJSX(source, true)}>
                            <div>{source.substring(0, maxChars+7) + "..."}</div>
                        </StyledTooltip>);
        }


        let deliverTo = job.deliverTo();
        if (deliverTo.length > maxChars+10) {
            deliverTo = (<StyledTooltip title={multilineJSX(deliverTo, true)}>
                            <div>{deliverTo.substring(0, maxChars+7) + "..."}</div>
                        </StyledTooltip>);
        }
        
        maxChars = this.state.isSmall ? 20 : 30;

        let group = job.group;
        if (group.length > maxChars) {
            group = <StyledTooltip title={multilineJSX(group, true)}>
                        <div>{group.substring(0, maxChars-3) + "..."}</div>
                    </StyledTooltip>;
        };

        let membershipID = job.membershipID;
        if (membershipID && (membershipID.length > maxChars)) {
            membershipID = <StyledTooltip title={multilineJSX(membershipID, true)}>
                        <div>{membershipID.substring(0, maxChars-3) + "..."}</div>
                    </StyledTooltip>;
        };

        let specialHandling = job.specialHandling;
        if (specialHandling && (specialHandling.length > maxChars)) {
            specialHandling = <StyledTooltip title={multilineJSX(specialHandling, true)}>
                        <div>{specialHandling.substring(0, maxChars-3) + "..."}</div>
                    </StyledTooltip>;
        };
        
        const isRevealed = this.state.revealSet.has(job);
        
        const leftIcon = isRevealed ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
                                
        const columns = this.state.isSmall ? 10 : 13;      
        
        let claimColor = 'lightGray'; //default - not claimable
        let claimTooltip = "Not Claimable";
        if (job.claimable()){
            claimColor = 'green';
            claimTooltip = "Claimable (click to claim)";
        }
        else if (job.claimedByUser()) {
            claimColor = ThemeColors.claimedPurple;
            claimTooltip = "Claimed by you (click to release claim)";
        }

        const actionItems = this._getActionItems(job);
                                
        return (
            <Fragment key={key}>
                <tr style={this.styles.tableStyle}>


                    <td style={this.styles.tableData}>

                        <IconButton edge="start" onClick={() => this._revealJobJournal(job)}>
                            {leftIcon}
                        </IconButton>
                    </td>

                    <td style={this.styles.tableData}>{submitDateAndTime}</td>

                    <td style={this.styles.tableData}>
                        <div style={{...this.styles.status, backgroundColor: job.stateEnum.backgroundColor}}>
                            {job.stateEnum.label}
                        </div>
                    </td>
                    
                    <td style={this.styles.tableData}>{kindImage}</td>
                    <td style={{...this.styles.tableData, width: 40, textAlign: 'center'}}>{job.passCount ? job.passCount : ""}</td>
                    <td style={this.styles.tableData}>
                        {job.renderBadges()}
                    </td>
                    <td style={this.styles.tableData}>{specialHandling}</td>

                    {this.state.isSmall ? null :
                        <Fragment>
                            <td style={this.styles.tableData}>{job.claimerName}</td>
                            <td style={this.styles.tableData}>{source}</td>
                        </Fragment>
                    }                          

                    <td style={this.styles.tableData}>{deliverTo}</td>
                    <td style={this.styles.tableData}>{group}</td>

                    {this.state.isSmall ? null :
                        <td style={this.styles.tableData}>{membershipID}</td>
                    }

                    <td style={{...this.styles.tableData, paddingRight: 0}}>
                        <div style={{display: 'flex', justifyContent: 'right'}}>
                            <Tooltip title={claimTooltip}>
                                <IconButton disabled={!job.claimable() && !job.claimedByUser()} onClick={() => this._claim(job)}>
                                    <AssignmentIcon style={{color: claimColor}}/>
                                </IconButton>
                            </Tooltip>                     
                            <div style={{marginRight: 10}}/>
                            <PopupMenu menuIcon={(<SettingsIcon style={{fontSize: '20', color: actionItems.length > 0 ? ThemeColors.settingsOlive : 'lightGray'}}/>)}  
                                                menuItems={actionItems} 
                                                menuTooltipText={"Other Actions"}/>
                        </div>
                    </td>
                </tr>
                <tr style={this.styles.tableStyle}>
                    <td colSpan={columns} align='center' style={this.styles.tableDataWithBorder}>
                        <Collapse in={isRevealed}>
                            {job.renderJournal()}
                        </Collapse>
                    </td>
                </tr>
            </Fragment>
        );
    }
    
    
    _checkboxLabels = (function(){
        const checkboxLabels = [];
        for (let index in Job.State) {
            const state = Job.State[index];

            checkboxLabels.push({name: state.label, tooltip: null});
        } 
        return checkboxLabels;
    })();  //iffe
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
                                 
        const page = this.state.jobsPage;
        const canBatchPrint = page && page.batchPrintableCount > 0;  //claimed at least one job
       
        const showing = (page && page.jobs.length > 0) ? "Displaying " + (page.index + 1) + "-" + (page.index + page.jobs.length) : null;            
        
        const advancedIcon = this.state.showAdvancedSearch ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
        
       
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}

                <CommunitySelectorPopup isOpen={this.state.communitySelectorPopoverOpen} communities={this.state.bulkCommunities} 
                                        okCallback={(community) => this._selectBulkMailCommunity(community)} 
                                        cancelCallback={() => this.setState({communitySelectorPopoverOpen: false})}/>  


                <TextEntryPopover isOpen={this.state.commentPopupOpen} showSkip={false} multiline={true} title="Add Comment to Job"
                                  okCallback={this._commentOkCallback} cancelCallback={() => this.setState({commentPopupOpen: false, commentJob: null})} />
                
                {page ?
                    <Grid container direction="row" spacing={2} style={{padding: 10}}>

                            {page.agsBacklog != null ? 
                                <Fragment>
                                    <Grid item xs={4}>
                                        <SummaryWidget label="AGS Print and Mail Backlog"
                                                        value={page.agsBacklog}
                                                        tooltip={"Total Jobs remaining to be processed (not including Bulk Request Kinds)"}
                                                        borderColor='black'/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SummaryWidget label="Oldest Backlog Job (Days Ago)"
                                                        value={page.oldestBacklog == null ? "N/A" : page.oldestBacklog }
                                                        tooltip={"How many days old the oldest job in the AGS Backlog is (not including Bulk Request Kinds"}
                                                        borderColor='black'/>
                                    </Grid> 
                                    <Grid item xs={4}>
                                        <SummaryWidget label="Outstanding Bulk Mail Requests"
                                                        value={page.agsBulkCount}
                                                        tooltip={"How many outstanding Bulk Mail Requests are waiting to be processed by AGS"}
                                                        borderColor='black'/>
                                    </Grid>   
                                </Fragment>
                            : null}
                            

                            {page.stateCounts.map((stateCount, index) => 
                                <Grid item md={2} xs={4} key={index}>
                                    <SummaryWidget label={stateCount.state.label}
                                                   value={stateCount.count}
                                                   tooltip={"Total " + stateCount.state.label + " Jobs for this search/query"}
                                                   borderColor={stateCount.state.backgroundColor}/>
                                </Grid>                  
                            )}

                    </Grid>
                    : null
                }
        
               
                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'left', alignItems: 'center'}}>
                
                    <Tooltip title="All Jobs (except Archived), ordered from newest to oldest">
                        <Button fullWidth size='small' style={{backgroundColor: ThemeColors.veryLightBlue, color: 'black', maxWidth: 200}} onClick={this._fetchAll} variant="contained" component="label">
                            All
                        </Button>
                    </Tooltip>                    
                    <Tooltip title="Jobs in the Queued State, ready for processing, ordered from oldest to newest">
                        <Button fullWidth size='small' style={{backgroundColor: Job.State.QUEUED.backgroundColor, color: 'white', maxWidth: 200}} onClick={this._fetchQueued} variant="contained" component="label">
                            Ready for Processing
                        </Button>
                    </Tooltip>
                    {Global.user.hasPermissionTo(Permissions.PRINT) ? 
                        <Tooltip title="Jobs in the Queued State, ready for processing, sorted by community">
                            <Button fullWidth size='small' style={{backgroundColor: Job.State.QUEUED.backgroundColor, color: 'white', maxWidth: 200}} onClick={this._fetchQueuedByCommunities} variant="contained" component="label">
                                Queued by Community
                            </Button>
                        </Tooltip>
                        : null
                    }
                    <Tooltip title="Jobs in the Claimed State, claimed by me, ordered from oldest to newest">
                        <Button fullWidth size='small' style={{backgroundColor: Job.State.CLAIMED.backgroundColor, color: 'white', maxWidth: 200}} onClick={this._fetchClaimedByMe} variant="contained" component="label">
                            Claimed by Me
                        </Button>
                    </Tooltip>

                    {this.state.isBusy ? this.getBusyComponent('left', {marginLeft: 20, padding: 15}, 30) : 
                        <Tooltip title="Refresh">
                            <div style={{display: 'flex'}}>
                                <IconButton edge="end" onClick={this._refresh} color='primary' style={{marginLeft: 0, marginRight: 1, marginBottom: 1}}>
                                    <RefreshIcon fontSize="large"/>
                                </IconButton>
                            </div>
                        </Tooltip>}

                    <div style={{flexGrow: 1}}/> 

                    <Tooltip title="Request a Bulk Mailing for Printed Passes">
                        <Button fullWidth size='small' style={{backgroundColor: ThemeColors.bulkMailTan, color: 'white', maxWidth: 200, marginRight: 10}} 
                                onClick={this._bulkMail} variant="contained" component="label" startIcon={<EmailIcon/>}>
                            Request Bulk Mailing
                        </Button>
                    </Tooltip>
                    
                    {Global.user.hasPermissionTo(Permissions.PRINT) ? 
                        <Tooltip title="Batch Print all Claimed Jobs">
                            <Button fullWidth size='small' disabled={!canBatchPrint} style={{backgroundColor: canBatchPrint ? ThemeColors.batchGreen : 'lightGray', color: 'white', maxWidth: 200}} 
                                    onClick={this._batchPrint} variant="contained" component="label" startIcon={<PrintIcon/>}>
                                Batch Print
                            </Button>
                        </Tooltip>
                        : null
                    }

                </div>
                
                <div>
                    <IconButton edge="start" onClick={() => this.setState({showAdvancedSearch: !this.state.showAdvancedSearch})}>
                        {advancedIcon}
                    </IconButton> 
                    <Typography variant="button">Advanced Search</Typography>                  
                </div>
                <Collapse in={this.state.showAdvancedSearch}> 
                    <Paper style={this.styles.paper}>
                        
                        <Typography variant="body2" style={this.styles.paperLabel}>Search Jobs</Typography>  

                        <Grid container direction="row" spacing={3} style={{padding: 20}}>

                            <Grid item md={4} sm={6} xs={12}>

                                <DateRangeSelector calendarColor={ThemeColors.calendarColor}
                                                   dateFormat={null}
                                                   timeOptions={this._searchTimeRangeOptions}
                                                   minYear={2020}
                                                   ref={this._dateRangeRef}
                                                   initialTimeRange="Year to Date"
                                                   initialStartDate={this.state.searchFromDate}
                                                   initialEndDate={this.state.searchToDate}
                                                   onDateChange={this._dateChangeCallback}
                                                   onParseError={this._dateParseError}/>

                            </Grid>


                            <Grid item md={4} sm={6} xs={12}>

                                <div style={{width: '100%'}}>  

                                    <Autocomplete
                                        size='small'
                                        value={this.state.groupToSearch}
                                        onChange={(event, newValue) => { this.setState({groupToSearch: newValue}); }}
                                        inputValue={this.state.groupToSearchValue}
                                        onInputChange={(event, newValue) => { this.setState({groupToSearchValue: newValue}); }}
                                        options={this._availableGroups}
                                        blurOnSelect
                                        renderInput={(params) => <TextField {...params} label="Community" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                    /> 

                                    <ManageTextField style={{marginTop: 20}} autoAccept={true} fullWidth={true} justify='left' label="Delivery Data"                           
                                                    tooltip="Search on Membership ID, Delivery Address or Email"
                                                    json="patronData"
                                                    onFieldChange={(json, val) => {console.log("text: " + val); this.setState({patronDataToSearch: val})}} changedBackgroundColor='white'/>

                                    <ManageTextField style={{marginTop: 20}} autoAccept={true} fullWidth={true} justify='left' label="Journal Data"                           
                                                    tooltip="Search for matching text in a Journal Entry, including author, activity, and notes"
                                                    json="journalData"
                                                    onFieldChange={(json, val) => this.setState({journalDataToSearch: val})} changedBackgroundColor='white'/>

                                </div>                                    
                            </Grid>

                            <Grid item md={4} sm={12} xs={12}>
                                <ManageBitwiseCheckboxes style={{marginTop: -17}} json="stateCheckboxes" label="States" labels={this._checkboxLabels} onChange={this._checkboxStateChanged} initialValue={this.state.stateCheckboxes} />
                           </Grid>

                        </Grid>
                        <div style={{display: 'flex', justifyContent: 'center'}}>

                            <Tooltip title="Search and Display Jobs below">
                               <Button fullWidth onClick={this._advancedSearch} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<SearchIcon />}>
                                   Search
                               </Button>
                            </Tooltip>
                            <Tooltip title="Search and Download Jobs as .csv">
                               <Button fullWidth onClick={this._export} variant="outlined" color='primary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<GetAppIcon/>}>
                                   Download
                               </Button>
                            </Tooltip>

                            {Global.user.hasPermissionTo(Permissions.ADMINISTER_PASSES) &&  this.state.stateCheckboxes === archivedStateBit ? 
                                <Tooltip title="Delete Jobs">
                                    <Button fullWidth onClick={this._bulkDeleteJobs} variant="outlined" color='secondary' style={{margin: 20, maxWidth: 200}} component="label" startIcon={<DeleteIcon/>}>
                                        Delete
                                    </Button>
                                </Tooltip>
                                : null
                            }


                        </div>

                    </Paper>
                </Collapse>
                
                <div style={{marginTop: 15}}/>
                
                {page ?
                     <div>   
                     
                        <table style={this.styles.table}>
                            <thead>
                               {this._renderRow(null, 0) /*render header*/ }
                            </thead>
                            <tbody>
                                {page.jobs.map((job, index) => this._renderRow(job, index+1))}
                            </tbody>
                        </table>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body2" style={{color: ThemeColors.darkGray}}>{showing}</Typography> 
                            <div style={{marginLeft: 'auto'}}>
                                {!page.isFirst() ? <Button onClick={this._prevPage} variant="outlined" color='primary' component="label" >
                                               Prev
                                           </Button>
                                           : null}

                                {!page.isLast() ? <Button onClick={this._nextPage} variant="outlined" color='primary' style={{marginLeft: 10}} component="label" >
                                               Next
                                           </Button>
                                           : null}
                            </div>            
                        </div>                        

                    </div>
                    
                    
                : null}
                
                
            </Fragment>
        );
        
    }
}



export default withCookies(withRouter(JobsListPage));

